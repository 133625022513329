require('three/build/three.min.js');
require('three/examples/js/loaders/STLLoader.js');
require('three/examples/js/controls/OrbitControls.js');

export default class STLViewer {
  constructor(elem)  {
    this.elem = elem;

    this.init();
  }

  init() {
    // if (!WEBGL.isWebGLAvailable()) {
    //     elem.appendChild(WEBGL.getWebGLErrorMessage());
    //     return;
    // }

    var elem = this.elem
    var renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    var camera = new THREE.PerspectiveCamera(70, elem.clientWidth / elem.clientHeight, 1, 1000);

    renderer.setSize(elem.clientWidth, elem.clientHeight);
    elem.appendChild(renderer.domElement);

    window.addEventListener('resize', function () {
        renderer.setSize(elem.clientWidth, elem.clientHeight);
        camera.aspect = elem.clientWidth / elem.clientHeight;
        camera.updateProjectionMatrix();
    }, false);

    var controls = new THREE.OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.rotateSpeed = 0.30;
    controls.dampingFactor = 0.1;
    controls.enableZoom = true;
    controls.enablePan = false;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 1.0;

    var scene = new THREE.Scene();
    scene.add(new THREE.HemisphereLight(0xffffff, 0x080820, 1.5));

    this.scene = scene;
    this.camera = camera;

    this.controls = controls;
    this.renderer = renderer;
  }

  remove() {
    this.scene.remove( this.mesh );
  }

  add(model) {
    var scene = this.scene;
    var camera = this.camera;

    var controls = this.controls;
    var renderer = this.renderer;

    (new THREE.STLLoader()).load(model, (geometry) => {
      var material = new THREE.MeshPhongMaterial({ color: 0x003D77, specular: 100, shininess: 100 });
      var mesh = new THREE.Mesh(geometry, material);
      scene.add(mesh);

      // Compute the middle
      var middle = new THREE.Vector3();
      geometry.computeBoundingBox();
      geometry.boundingBox.getCenter(middle);

      // Center it
      mesh.position.x = -1 * middle.x;
      mesh.position.y = -1 * middle.y;
      mesh.position.z = -1 * middle.z;

      // Pull the camera away as needed
      var largestDimension = Math.max(geometry.boundingBox.max.x,
          geometry.boundingBox.max.y, geometry.boundingBox.max.z)
      camera.position.z = largestDimension * 2.5;

      var animate = function () {
          requestAnimationFrame(animate);
          controls.update();
          renderer.render(scene, camera);
      }; animate();

      this.mesh = mesh;
    });
  }
}