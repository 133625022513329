import { Controller } from "@hotwired/stimulus"
import STLViewer from 'js/stl_viewer';

export default class extends Controller {
  static values = { file: String }
  static targets = ["viewer", "icon"];

  connect() {
    this.load()
  }

  load() {
    if (this.fileValue) {
      this.viewer = new STLViewer(this.viewerTarget);
      this.viewer.add(this.fileValue);
    } else {
      this.iconTarget.hidden = false;
    }
  }
}