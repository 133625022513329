import { Controller } from "stimulus";

import Dropzone from "dropzone";
import STLViewer from 'js/stl_viewer';
import { DirectUpload } from "@rails/activestorage";

import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

export default class extends Controller {
  static targets = ["input", "viewer", "dropzone", "loader", "submit"];

  connect() {
    this.hideFileInput();

    Dropzone.autoDiscover = false;

    this.file = null;
    this.dropZone = createDropZone(this);

    this.bindEvents();
  }

  // Private
  hideFileInput() {
    this.inputTarget.hidden = true;
    this.viewerTarget.hidden = true;
  }

  showLoader() {
    // show viewer and loader
    this.loaderTarget.hidden = false;

    // hide dropzone
    this.dropzoneTarget.hidden = true;

    // disable button
    this.submitTarget.disabled = true;
  }

  hideLoader() {
    // hide loader and enable button
    this.loaderTarget.hidden = true;
    this.submitTarget.disabled = false;
  }

  bindEvents() {
    var viewer = this.viewer;

    this.dropZone.on("addedfile", file => {
      this.file = file;

      setTimeout(() => {
        if (file.accepted) {
          this.showLoader();
          this.dropZone.processQueue();
        }
      }, 500);
    });

    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);

      viewer.remove()
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("success", (file, response) => {
      this.hideLoader();
      this.viewerTarget.hidden = false;

      // element must be visible
      this.viewer = new STLViewer(this.viewerTarget);

      this.viewer.add(response);
      createDirectUploadController(this, this.file).start();
    });

    this.dropZone.on("error", (file, response) => {
      document.location.reload();
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get directUploadUrl() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get processUrl() {
    return '/converter'
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.directUploadUrl, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();

    this.directUpload.create((error, attributes) => {
      if (error) {
        console.log(error)
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    // this.xhr = xhr;
    // this.xhr.upload.addEventListener("progress", event =>
    //   this.uploadRequestDidProgress(event)
    // );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    // this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.dropzoneTarget, {
    url: controller.processUrl,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    disablePreviews: true,

    // autoQueue: false,
    autoProcessQueue: false
  });
}
